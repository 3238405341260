<template>
  <div class="mb-3 mt-3">
    <div class="row align-items-center">
      <div class="col-auto">
        <b-icon :icon="icon" font-scale="2"></b-icon>
      </div>
      <div class="col pl-0">
        <div>
          <small
            ><b>{{ text }}</b></small
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'note',
  props: ['text', 'icon'],
};
</script>

<style lang="less">
@import '@/assets/lessjs/variables.less';
</style>
