<template>
  <div class="imageUploader mb-3">
    <div class="caption">
      <div class="text-center">
        <b-icon icon="camera" font-scale="2" />
        <div>
          <small class="upload-caption">上傳照片</small>
        </div>
      </div>
    </div>
    <input class="fileInput" type="file" @change="setImage" />
    <div class="imageWrapper">
      <b-icon
        v-if="isLoading || previewFile"
        class="deleteIcon"
        icon="x-circle-fill"
        font-scale="1.8"
        @click="stopUploading"
      />
      <div v-show="isLoading" class="loadingMask">
        <b-spinner style="z-index: 999" label="Loading..."></b-spinner>
      </div>
      <img v-if="previewFile" class="previewImage" :src="previewFile" alt="" />
    </div>
  </div>
</template>

<script>
import { Observable, TimeoutError } from 'rxjs';
import { map, retryWhen, delay, timeout } from 'rxjs/operators';
import { mapState } from 'vuex';
import { upload, preview } from '@/utils/uploader';
import { auth } from '@/mixins';

export default {
  mixins: [auth],
  props: [],
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
    }),
  },
  data() {
    return {
      previewFile: null,
      subscription: null,
      isLoading: false,
    };
  },
  components: {},
  watch: {
    isLoading: {
      handler(newVal) {
        if (newVal) {
          this.$emit('onImgUploading', true);
        } else {
          this.$emit('onImgUploading', false);
        }
      },
    },
  },
  methods: {
    processor(data) {
      const { state, value } = data;
      switch (state) {
        case 'requesting':
          this.isLoading = true;
          break;
        case 'preview':
          if (!this.previewFile) {
            this.previewFile = value;
          }
          break;
        case 'uploaded':
          // this.isLoading = false;
          this.$emit('setImage', value.fileKey);
          break;
        default:
          break;
      }
    },
    uploadFile({ file }) {
      this.isLoading = true;
      const { token } = this;
      const files$ = Observable.create(async (observer) => {
        try {
          observer.next({ state: 'requesting', value: null });
          const previewFile = await preview(file);
          observer.next({ state: 'preview', value: previewFile });
          const uploadedFile = await upload({ file, token });
          observer.next({ state: 'uploaded', value: uploadedFile });
          observer.complete();
        } catch (e) {
          observer.error(e);
        }
      }).pipe(
        timeout(35000),
        retryWhen((errorObs) =>
          errorObs.pipe(
            map((err, index) => {
              if (index === 2) {
                throw err;
              }
              return err;
            }),
            delay(2000)
          )
        )
      );
      const subscription = files$.subscribe({
        next: this.processor,
        complete: () => {
          this.isLoading = false;
        },
        error: (error) => {
          if (error instanceof TimeoutError) {
            alert('網路異常，上傳失敗。');
          }
          console.error(`Throw Error: ${error}`);
        },
      });
      this.subscription = subscription;
    },

    stopUploading() {
      this.subscription.unsubscribe();
      this.subscription = null;
      this.isLoading = false;
      this.previewFile = null;
    },

    setImage(e) {
      this.uploadFile({ file: e.target.files[0] });
    },
  },
};
</script>

<style lang="less">
.fileInput {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.imageWrapper {
  height: 100%;
  width: 100%;
}

.deleteIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 50;
}

.previewImage {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  background-color: #fff;
}

.loadingMask {
  position: absolute;
  z-index: 30;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.imageUploader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  border: 1px dashed dimgrey;
  color: dimgrey;
  img {
    position: relative;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}
</style>
