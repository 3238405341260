<template>
  <div id="contract-nav" class="container-fluid">
    <div class="actionBar">
      <div class="row text-center">
        <div
          class="col-6 p-0 justify-content-center"
          v-show="step == 0 || step > states.length - 1"
        >
          <router-link to="/menu" class="sub">
            <div class="navItem">返回主選單</div>
          </router-link>
        </div>
        <div class="col-6 p-0" v-show="step != 0 && step <= states.length - 1">
          <div @click="$emit('prevStep')" class="navItem">上一步</div>
        </div>
        <div class="col-6 p-0" v-show="step < states.length - 1">
          <div
            @click="$emit('nextStep')"
            class="navItem highlight"
            :class="disabledNext && 'disabled'"
          >
            下一步
          </div>
        </div>
        <div class="col-6 p-0" v-show="step == states.length - 1">
          <div
            @click="$emit('onSubmit')"
            class="navItem highlight"
            :class="disabledNext && 'disabled'"
          >
            完成
          </div>
        </div>
        <div class="col-6 p-0 justify-content-center" v-show="step > states.length - 1">
          <router-link :to="end.link">
            <div class="navItem highlight">{{ end.action }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['step', 'states', 'end', 'onSubmit', 'disabledNext'],
  components: {},
};
</script>
<style lang="less">
@import '@/assets/lessjs/variables.less';

#contract-nav {
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.navItem {
  background-color: @dark-grey;
  color: white;
  font-size: 0.8em;
  padding: 1em;
  cursor: pointer;

  &.highlight {
    background-color: @highlight;
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
