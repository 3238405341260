<template>
  <div class="stepTabBar p-2">
    <div class="px-3">
      <div class="wrapper">
        <div class="row text-center">
          <div class="col-auto p-0 pr-1">
            <div class="logo"></div>
          </div>
          <div class="col">
            <div class="row align-items-center">
              <div
                class="col pr-0"
                :key="state"
                v-for="(state, stateInex) in states"
                :class="{ current: stateInex == step }"
              >
                <div class="row w-100">
                  <div class="col p-0">
                    <small
                      ><b>{{ state }}</b></small
                    >
                  </div>
                  <div class="col-auto p-0" v-show="stateInex != states.length - 1">
                    <b-icon icon="chevron-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['step', 'states'],
};
</script>
<style lang="less">
@import '@/assets/lessjs/variables.less';
.logo {
  background-image: url(../assets/logo_landing@2x.png);
  background-position: center top;
  background-size: 60px;
  width: 30px;
  height: 30px;
}
.stepTabBar {
  border-bottom: 1px solid @light-grey;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.41);
  color: @light-grey;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
  background-color: white;
}

.current {
  color: black;
}
</style>
