/* eslint-disable no-async-promise-executor */
export const preview = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new window.FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });

const API_HOST = process.env.VUE_APP_API_HOST;
const S3_HOST = process.env.VUE_APP_S3_HOST;

export function upload({ file, type, token, imgQuality }) {
  return new Promise(async (resolve, reject) => {
    const fd = new FormData();
    fd.append('file', file);
    const xhr = new window.XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open(
      'POST',
      `${API_HOST}/api/media/upload?type=${type || 'PUBLIC'}&imgQuality=${imgQuality || 0.5}`
    );
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    // xhr.setRequestHeader('Authorization', `Bearer ${localStorage.accessToken}`);
    try {
      xhr.send(fd);
      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          try {
            const res = JSON.parse(this.responseText);
            const { id, fileKey } = res;
            if (id && fileKey) {
              resolve({
                id,
                fileKey,
                filename: file.name,
                url: `${S3_HOST}/${fileKey}`,
              });
            } else {
              reject(res);
            }
          } catch (e) {
            reject(e);
          }
        }
      });
    } catch (ex) {
      reject(ex);
    }
  });
}

export async function uploadFiles(files, cb, existedList = [], maxLength = Infinity, type) {
  const filenames = await Promise.all(
    Array.from(files)
      .slice(0, maxLength)
      .map((file) => upload(file, type))
  );

  cb([...existedList, ...filenames].slice(0, maxLength));
}

export default uploadFiles;
