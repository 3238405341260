<template>
  <div class="view">
    <ProgressBar :step="step" :states="states" v-show="step != 3" />
    <div class="main">
      <div>
        <div class="wrapper h-100">
          <b-form @submit.stop.prevent="">
            <div v-show="step == 0">
              <div class="mb-5 px-3">
                <h6 style="color: #ff0000">必填欄位*</h6>
                <div class="row mt-3 align-items-center">
                  <div class="col">
                    <h4>事故時間<span class="required">*</span></h4>
                  </div>
                  <div class="col-auto">
                    <a href="#" @click="getCurrentDate">
                      <b-icon icon="clock"></b-icon>
                      取得目前時間
                    </a>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6 pr-1">
                    <b-form-datepicker
                      id="step1_date"
                      placeholder="選擇日期"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      locale="zh"
                      v-model="$v.step1.date.$model"
                      :state="validateState('date', 'step1')"
                    />
                    <b-form-invalid-feedback id="step1_date">必填</b-form-invalid-feedback>
                  </div>
                  <div class="col-6 pl-1">
                    <b-form-timepicker
                      placeholder="選擇時間"
                      locale="zh"
                      v-model="$v.step1.time.$model"
                      :state="validateState('time', 'step1')"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-5 px-3">
                <div class="row align-items-center">
                  <div class="col">
                    <h4>事故地點<span class="required">*</span></h4>
                  </div>
                  <div class="col-auto" @click="getCurrentLocation">
                    <a href="#">
                      <b-icon icon="cursor"></b-icon>
                      取得目前地點
                    </a>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6 pr-1">
                    <b-form-select
                      :options="city_options"
                      v-model="$v.step1.city.$model"
                      :state="validateState('city', 'step1')"
                      @change="onCityChange"
                    />
                  </div>
                  <div class="col-6 pl-1">
                    <div class="row align-items-center">
                      <div class="col-auto text-center pr-0 ml-2" v-show="$v.step1.zip_code">
                        {{ $v.step1.zip_code }}
                      </div>
                      <div class="col">
                        <b-form-select
                          v-model="$v.step1.area.$model"
                          :state="validateState('area', 'step1')"
                          :options="area_options"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12">
                    <b-form-input
                      id="step1_address"
                      v-model="$v.step1.address.$model"
                      :state="validateState('address', 'step1')"
                      type="text"
                      placeholder="輸入段/路/街/巷/號"
                    />
                    <b-form-invalid-feedback id="step1_address">必填</b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div class="mb-3 px-3">
                <h4>事故地點額外描述</h4>
                <div class="row mb-3">
                  <div class="col-12">
                    <b-form-textarea
                      v-model="step1.location_description"
                      placeholder="例如：國道一號南下200公尺處"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-form>
          <div v-show="step == 1">
            <div class="mt-3 mb-5 p-3">
              <h6 style="color: #ff0000">必填欄位*</h6>
              <h4>我方身份紀錄</h4>
              <div class="row mb-3">
                <div class="col mb-3">
                  <h6>身份選擇<span class="required">*</span></h6>
                  <div class="row px-3 identity-selector">
                    <div
                      class="col-4 card"
                      :id="item.value"
                      v-for="item in $store.state.identity_options"
                      :key="item.key"
                      :state="validateState('creator_identity', 'step2')"
                      :class="{
                        selected: item.value === $v.step2.creator_identity.$model,
                      }"
                      @click="
                        {
                          setCreatorIDSelector(item.value);
                        }
                      "
                    >
                      {{ item.text }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6" v-show="step2.creator_identity === 'OTHERS'">
                  <h6>身份說明<span class="required">*</span></h6>
                  <b-form-input
                    v-model="$v.step2.creator_identity_description.$model"
                    :state="validateState('creator_identity_description', 'step2')"
                    type="text"
                  />
                </div>
                <div class="col-lg-6" v-show="step2.creator_identity == 'DRIVER'">
                  <h6>車牌號碼<span class="required">*</span></h6>
                  <b-form-input
                    id="step2_creator_plate"
                    v-model="$v.step2.creator_plate.$model"
                    :state="validateState('creator_plate', 'step2')"
                    type="text"
                  />
                  <b-form-invalid-feedback id="step2_creator_plate">必填</b-form-invalid-feedback>
                </div>
              </div>
              <div class="row mb-3" v-show="step2.creator_identity == 'DRIVER'">
                <div class="col">
                  <h6>車牌照片</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step2.creator_plate_photo_id = $event"
                  />
                  <Notes
                    text="若我們無法開啟您的相機，請您先行拍攝照片再由相簿上傳"
                    icon="exclamation-circle"
                  />
                </div>
              </div>
            </div>
            <div class="mb-5 px-3 py-5 bg-grey">
              <h4>對方身份紀錄</h4>
              <div class="row mb-3">
                <div class="col mb-3">
                  <h6>身份選擇<span class="required">*</span></h6>
                  <div class="row px-3 identity-selector">
                    <div
                      class="col-4 card"
                      :id="item.value"
                      v-for="item in $store.state.identity_options"
                      :key="item.key"
                      :state="validateState('opposite_identity', 'step2')"
                      :class="{
                        selected: item.value === $v.step2.opposite_identity.$model,
                      }"
                      @click="
                        {
                          setOppositeIDSelector(item.value);
                        }
                      "
                    >
                      {{ item.text }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6" v-show="step2.opposite_identity === 'OTHERS'">
                  <h6>身份說明<span class="required">*</span></h6>
                  <b-form-input
                    v-model="$v.step2.opposite_identity_description.$model"
                    :state="validateState('opposite_identity_description', 'step2')"
                    type="text"
                  />
                </div>
                <div class="col-lg-6" v-show="step2.opposite_identity === 'DRIVER'">
                  <h6>車牌號碼<span class="required">*</span></h6>
                  <b-form-input
                    id="step2_opposite_plate"
                    v-model="$v.step2.opposite_plate.$model"
                    :state="validateState('opposite_plate', 'step2')"
                    type="text"
                  />
                  <b-form-invalid-feedback id="step2_opposite_plate">必填</b-form-invalid-feedback>
                </div>
              </div>
              <div class="row mb-3" v-show="step2.opposite_identity === 'DRIVER'">
                <div class="col">
                  <h6>車牌照片</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step2.opposite_plate_photo_id = $event"
                  />
                  <Notes
                    text="若我們無法開啟您的相機，請您先行拍攝照片再由相簿上傳"
                    icon="exclamation-circle"
                  />
                </div>
              </div>
            </div>
            <div class="mb-5 px-3">
              <h4>紀錄車禍現場</h4>
              <div class="row mb-3">
                <div class="col-6">
                  <h6>我方車輛受損的地方</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step2.creator_damage_photo_id = $event"
                  />
                </div>
                <div class="col-6">
                  <h6>對方車輛受損的地方</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step2.opposite_damage_photo_id = $event"
                  />
                </div>
                <div class="col-lg-6">
                  <h6>請將兩台車拍攝在同一張照片</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step2.paimary_scene_photo_id = $event"
                  />
                </div>
                <div class="col-lg-6">
                  <h6>請換個角度拍攝兩台車的相對位置</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step2.secondary_scene_photo_id = $event"
                  />
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col">
                  <Notes
                    text="若我們無法開啟您的相機，請您先行拍攝照片再由相簿上傳"
                    icon="exclamation-circle"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="px-3" v-show="step == 2">
            <h6 style="color: #ff0000">必填欄位*</h6>
            <div class="mt-3 mb-5">
              <h4>我方受傷狀況</h4>
              <h6>狀態選擇<span class="required">*</span></h6>
              <div class="row mb-3">
                <div class="col-auto">
                  <b-form-radio v-model="step3.creator_is_injury" name="a_inhury" value="true">
                    有受傷
                  </b-form-radio>
                </div>
                <div class="col-auto">
                  <b-form-radio v-model="step3.creator_is_injury" name="a_inhury" value="false">
                    無受傷
                  </b-form-radio>
                </div>
              </div>
              <div class="row mb-3" v-show="step3.creator_is_injury === 'true'">
                <div class="col">
                  <h6>身體受傷照片</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step3.creator_injury_photo_id = $event"
                  />
                  <Notes
                    text="若我們無法開啟您的相機，請您先行拍攝照片再由相簿上傳"
                    icon="exclamation-circle"
                  />
                </div>
              </div>
            </div>
            <div class="mb-5">
              <h4>對方受傷狀況</h4>
              <h6>狀態選擇<span class="required">*</span></h6>
              <div class="row mb-3">
                <div class="col-auto">
                  <b-form-radio v-model="step3.opposite_is_injury" name="b_inhury" value="true">
                    有受傷
                  </b-form-radio>
                </div>
                <div class="col-auto">
                  <b-form-radio v-model="step3.opposite_is_injury" name="b_inhury" value="false">
                    無受傷
                  </b-form-radio>
                </div>
              </div>
              <div class="row mb-3" v-show="step3.opposite_is_injury === 'true'">
                <div class="col">
                  <h6>身體受傷照片</h6>
                  <ImageUploader
                    @onImgUploading="imgUploading = $event"
                    @setImage="step3.opposite_injury_photo_id = $event"
                  />
                  <Notes
                    text="若我們無法開啟您的相機，請您先行拍攝照片再由相簿上傳"
                    icon="exclamation-circle"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-show="step == 3" class="h-100">
            <div class="row align-items-center justify-content-center text-center h-100">
              <div class="col align-self-center">
                <h1 class="display-1">
                  <b-icon icon="check-circle"></b-icon>
                </h1>
                <h4>事故紀錄完成</h4>
                <h6>Pass And Move On!</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="requestingCreateRecord" :can-cancel="false" :is-full-page="true" />
    <loading :active.sync="requestingGeoLocation" :can-cancel="false" :is-full-page="true" />
    <NavBar
      :step="step"
      :states="states"
      :end="end"
      :disabledNext="imgUploading"
      v-on:onSubmit="onSubmit"
      v-on:nextStep="onNextStep(step)"
      v-on:prevStep="step -= 1"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { format, parseISO } from 'date-fns';
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import ProgressBar from '@/components/ProgressBar.vue';
import NavBar from '@/components/NavBar.vue';
import Notes from '@/elements/Notes.vue';
import ImageUploader from '@/elements/ImageUploader.vue';
import { record } from '@/mixins';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  mixins: [record, validationMixin],
  name: 'CreateEvent',
  props: {},
  components: {
    Notes,
    NavBar,
    ProgressBar,
    ImageUploader,
    Loading,
  },
  computed: {
    ...mapState({
      city_options: (state) => state.records.city,
      area_options: (state) => state.records.area,
      requestingCreateRecord: (state) => state.records.requestingCreateRecord,
    }),
  },
  methods: {
    setCreatorIDSelector(val) {
      this.step2.creator_identity = val;
    },
    setOppositeIDSelector(val) {
      this.step2.opposite_identity = val;
    },
    getCurrentDate() {
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'yyyy-MM-dd');
      const formattedTime = format(currentDate, 'HH:mm:ss');
      this.step1.date = formattedDate;
      this.step1.time = formattedTime;
    },
    getCurrentLocation() {
      this.requestingGeoLocation = true;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          this.requestingGeoLocation = false;
          console.log(error);
          alert(
            `您的瀏覽器不支援取得目前位置，或是尚未開啟位置存取權限，請檢查相關設定或直接輸入地址。`
          );
        },
        {
          enableHighAccuracy: true,
          timeout: 3000,
          maximumAge: 0,
        }
      );
    },
    async getStreetAddressFrom(lat, lng) {
      this.getAddressByLatLng({
        lat,
        lng,
        onSuccess: (res) => {
          const levelArr = res.data.addressComponents
            .filter((item) => item.type.indexOf('administrative_area_level') !== -1)
            .map((item) => ({
              ...item,
              type: item.type.replace('administrative_area_level_', ''),
            }))
            .sort((a, b) => {
              return a.type - b.type;
            });
          const res_city = levelArr[0]?.name;
          const res_area = levelArr[1]?.name;
          const cityId = this.city_options.find((item) => item.text === res_city)?.value;
          this.fetchAreaByCity({
            cityId,
            onSuccess: () => {
              this.step1.city = cityId;
              this.step1.area = this.area_options.find((item) => item.text === res_area)?.value;
              this.step1.address = res.data.address.split(res_area).pop();
              this.requestingGeoLocation = false;
            },
            onFailed: () => {
              this.requestingGeoLocation = false;
              alert('取得地址發生錯誤，請再試一次');
            },
          });
        },
        onFailed: () => {
          this.requestingGeoLocation = false;
          alert('取得地址發生錯誤，請再試一次');
        },
      });
    },
    setImage(file) {
      this.hasImage = true;
      this.image = file;
    },
    onCityChange(val) {
      this.fetchAreaByCity({
        cityId: val,
        onSuccess: (res) => {
          this.step1.area = res.data[0].id;
        },
      });
    },
    onSubmit() {
      const { step1, step2, step3 } = this;
      this.createRecord({
        address: step1.address,
        area: this.area_options.find((v) => v.value === step1.area).text,
        city: this.city_options.find((v) => v.value === step1.city).text,
        locationDescription: step1.location_description,
        occuredTime: parseISO(`${step1.date} ${step1.time}`),
        partyList: [
          {
            creator: true,
            damagePhoto: step2.creator_damage_photo_id,
            identity: step2.creator_identity,
            identityDescription: step2.creator_identity_description,
            injured: step3.creator_is_injury === 'true',
            injuryPhoto: step3.creator_injury_photo_id,
            plateNumber: step2.creator_plate,
            platePhoto: step2.creator_plate_photo_id,
          },
          {
            creator: false,
            damagePhoto: step2.opposite_damage_photo_id,
            identity: step2.opposite_identity,
            identityDescription: step2.opposite_identity_description,
            injured: step3.opposite_is_injury === 'true',
            injuryPhoto: step3.opposite_injury_photo_id,
            plateNumber: step2.opposite_plate,
            platePhoto: step2.opposite_plate_photo_id,
          },
        ],
        relativeDirectionPhoto1: step2.paimary_scene_photo_id,
        relativeDirectionPhoto2: step2.secondary_scene_photo_id,
        zipCode: step1.zip_code,
        onSuccess: () => {
          this.step += 1;
        },
      });
    },
    getCurrentStepModelKey(currentStep) {
      switch (currentStep) {
        case 0:
          return 'step1';
        case 1:
          return 'step2';
        case 2:
          return 'step3';
        default:
          return null;
      }
    },
    onNextStep(currentStep) {
      if (!this.imgUploading) {
        const key = this.getCurrentStepModelKey(currentStep);
        this.$v[key].$touch();
        if (this.$v[key].$anyError) {
          return;
        }

        this.step += 1;
      }
    },
    validateState(name, key) {
      const { $dirty, $error } = this.$v[key][name];
      return $dirty ? !$error : null;
    },
  },
  validations() {
    return {
      step1: {
        date: { required },
        time: { required },
        address: { required },
        city: { required },
        area: { required },
      },
      step2: {
        creator_identity: { required },
        creator_plate: this.step2.creator_identity === 'DRIVER' ? { required } : {},
        creator_identity_description: this.step2.creator_identity === 'OTHERS' ? { required } : {},
        opposite_identity: { required },
        opposite_plate: this.step2.opposite_identity === 'DRIVER' ? { required } : {},
        opposite_identity_description:
          this.step2.opposite_identity === 'OTHERS' ? { required } : {},
      },
    };
  },
  data() {
    return {
      step: 0,
      states: ['時間地點', '記錄現場', '受傷狀況'],
      end: {
        link: '/contract/create',
        action: '製作合約',
      },
      requestingGeoLocation: false,
      imgUploading: false,
      step1: {
        date: '',
        time: '',
        address: '',
        city: '1',
        area: '1',
        zip_code: '110',
        location_lat: null,
        location_lng: null,
        location_description: '',
      },
      step2: {
        creator_identity: 'DRIVER',
        creator_plate: '',
        creator_plate_photo_id: null,
        creator_identity_description: '',
        creator_damage_photo_id: null,
        opposite_identity: 'DRIVER',
        opposite_plate: '',
        opposite_plate_photo_id: null,
        opposite_identity_description: '',
        opposite_damage_photo_id: null,
        paimary_scene_photo_id: null,
        secondary_scene_photo_id: null,
      },
      step3: {
        creator_is_injury: false,
        creator_injury_photo_id: null,
        opposite_is_injury: false,
        opposite_injury_photo_id: null,
      },
    };
  },
  mounted() {
    this.fetchCityAndArea();
  },
};
</script>
<style lang="less">
@import '@/assets/lessjs/variables.less';

.bg-grey {
  background-color: #e5e5e5;
}

.required {
  color: #ff0000;
  font-size: 24px;
}

.identity-selector {
  .card {
    background-color: @bg-grey;
    cursor: pointer;
    padding-bottom: 35%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-blend-mode: luminosity;
    background-size: 100%;
    border-radius: 0;
    text-align: center;
    &.selected {
      background-color: @highlight;
    }
    &#DRIVER {
      background-image: url('../assets/bg_id_driver.png');
    }
    &#BIKEDRIVER {
      background-image: url('../assets/bg_id_biker.png');
    }
    &#PASSENGER {
      background-image: url('../assets/bg_id_pessanger.png');
    }
    &#PEDESTRIAN {
      background-image: url('../assets/bg_id_pedestrian.png');
    }
    &#OTHERS {
      background-image: url('../assets/bg_id_others.png');
    }
  }
}
</style>
